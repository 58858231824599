import { type FC } from 'react';

import { BaseEventCard } from './BaseEventCard';
import { type EventCardEventFragment } from './__generated-gql-types__/EventCard.generated';

interface EventCardProps {
  event: EventCardEventFragment;
}

export const EventCard: FC<EventCardProps> = ({ event }) => {
  return (
    <BaseEventCard
      type={event.__typename}
      name={event.shindigName}
      categories={event.categories?.map((cat) => cat.category)}
      image={event.imageLink}
      isFree
      date={event.date}
      coaches={event.coaches}
      link={event.link}
    />
  );
};
