import {
  IconClockAlt,
  IconSparkle,
  pluralize,
} from '@leland-dev/leland-ui-library';
import type { ReactNode } from 'react';

import Emoji from './Emoji';

interface SessionDetailsTokenProps {
  maximumAttendees?: Nullable<number>;
  totalAttendees?: number;
  large?: boolean;
}

const SessionDetailsToken: React.FC<SessionDetailsTokenProps> = ({
  maximumAttendees,
  totalAttendees,
  large,
}) => {
  const spotsLeft =
    maximumAttendees != null && totalAttendees != null
      ? maximumAttendees - totalAttendees
      : null;

  let className: string;
  let icon: ReactNode;
  let text: string;

  const iconClassName = large ? 'h-4.5 w-4.5' : 'h-4 w-4';

  if (!totalAttendees) {
    className = 'text-leland-blue';
    icon = <IconSparkle className={iconClassName} />;
    text = 'New';
  } else if (spotsLeft != null && spotsLeft <= 5) {
    className = 'text-leland-red';
    icon = <IconClockAlt className={iconClassName} />;
    text = `${pluralize(spotsLeft, 'spot')} left!`;
  } else if (totalAttendees > 1) {
    className = 'text-leland-orange';
    icon = <Emoji symbol="🔥" label="fire" />;
    text = 'Going Fast!';
  } else {
    return null;
  }

  return (
    <div className={`flex items-center ${className}`}>
      {icon}
      <p className={`ml-1 font-medium ${large ? '' : 'text-sm'}`}>{text}</p>
    </div>
  );
};

export default SessionDetailsToken;
