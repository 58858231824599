import { type FC } from 'react';

interface Props {
  symbol: string;
  label: string;
  className?: string;
}

const Emoji: FC<Props> = ({ symbol, label, className }) => {
  return (
    <div
      role="img"
      aria-label={label ? label : ''}
      aria-hidden={label ? 'false' : 'true'}
      className={`${className}`}
    >
      {symbol}
    </div>
  );
};

export default Emoji;
