// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
import {
  BaseEventCardCoachFragmentDoc,
  BaseEventCardCategoryFragmentDoc,
} from './BaseEventCard.generated';
export type EventCardEventFragment = {
  __typename?: 'Shindig';
  imageLink?: string | null;
  date: number;
  link: string;
  shindigId: string;
  shindigName: string;
  coaches?: Array<{
    __typename?: 'Coach';
    id: string;
    slug: string;
    reviewTotal: number;
    averageReviewRate: number;
    categoryInformationList?: Array<{
      __typename?: 'CategoryInformation';
      category: { __typename?: 'Category'; id: string; slug: string };
    }> | null;
    user: {
      __typename?: 'User';
      pictureLink?: string | null;
      firstName: string;
      lastName: string;
    };
  }> | null;
  categories?: Array<{
    __typename?: 'CategorySelection';
    category: { __typename?: 'Category'; id: string; slug: string };
  }> | null;
};

export const EventCardEventFragmentDoc = gql`
  fragment EventCard_Event on Shindig {
    shindigId: id
    shindigName: name
    imageLink
    coaches {
      ...BaseEventCard_Coach
    }
    categories {
      category {
        ...BaseEventCard_Category
      }
    }
    imageLink
    date
    link
  }
  ${BaseEventCardCoachFragmentDoc}
  ${BaseEventCardCategoryFragmentDoc}
`;
